<template>
  <div class="product_detail">
    <van-sticky>
      <van-nav-bar
        title="商品详情"
        left-text="返回"
        @click-left="onClickLeft"
        left-arrow
      />
    </van-sticky>
    <van-swipe @change="onChange">
      <van-swipe-item v-for="(item, index) in imgList" :key="index">
        <img :src="item" alt="" />
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">{{ current + 1 }}/4</div>
      </template>
    </van-swipe>
    <div class="item-info">
      <div class="item-info-container">
        <div class="item-info-master">
          <span class="label f12" style="background-color: rgb(120, 160, 209)"
            >直降</span
          >
          <span class="label f12" style="background-color: rgb(145, 188, 111)"
            >包邮</span
          >
          {{ detailObj.masterName }}
        </div>
        <div class="item-info-slave color-gray">
          {{ detailObj.slaveName }}
        </div>
        <div class="item-info-price flex jc-sb ai-fe mt-10">
          <div>
            <span class="price mr-5" style="font-size: 15px">￥</span>
            <span class="f20 price mr-5">{{ detailObj.minPrice }}</span>
            <span
              class="f12 old-price mr-5 color-gray"
              style="text-decoration: line-through"
              >￥{{ detailObj.maxPrice }}</span
            >
          </div>
          <span class="f12 color-gray">{{ detailObj.inventory }}人购买</span>
        </div>
      </div>
      <div>
        <van-cell
          is-link
          title="规格: X2"
          class="color-gray"
          @click="show = true"
        />
        <van-popup v-model="show" position="bottom" :style="{ height: '60%' }">
          <div class="topic flex">
            <img
              style="background-color: #f9f9f9"
              src="https://mall.s.maizuo.com/5f727ae93559d9c445e944a58211b538.png?x-oss-process=image/resize,w_563"
              alt=""
            />
            <div class="flex fdc jc-c ml-15">
              <div class="mb-5">
                <span class="price f14 mr-5">¥</span>
                <span class="f20 price">44.9</span>
              </div>
              <div class="f10">X&nbsp;&nbsp;2</div>
            </div>
          </div>
          <div class="count-box">
            <div class="count color-gray">数量</div>
            <van-stepper v-model="value" />
          </div>
        </van-popup>
      </div>
      <div>
        <van-cell
          is-link
          title="配送至"
          class="color-gray"
          @click="show2 = true"
        />
        <van-popup v-model="show2" position="bottom" :style="{ height: '60%' }">
          <div class="peisong">
            <div class="mb-10">
              <p style="font-size: 15px">请选择配送区域</p>
              <p style="color: red">未显示的地区即超出配送范围,无法进行选择</p>
            </div>
            <p class="f16">湖南省/衡阳市/耒阳市</p>
          </div>

          <van-area
            :area-list="areaList"
            :columns-placeholder="['请选择', '请选择', '请选择']"
          />
        </van-popup>
      </div>
      <div class="baged f12 flex ai-c">
        <i
          icon-size="12px"
          class="van-icon van-icon-passed"
          style="margin-right: 5px; color: red; padding-top: 4px"
          ><!----></i
        ><span class="color-gray">包邮</span>
      </div>
    </div>
    <div class="introduce">
      <div
        class="flex jc-c aic color-gray"
        style="background-color: white; height: 46px"
      >
        ----商品详情----
      </div>
      <div class="images">
        <div class="images">
          <img
            v-for="(item, index) in descPics"
            :src="item"
            :key="index"
            alt=""
          />
          <div
            class="mt-10 f12 flex aic jc-c color-gray"
            style="background-color: white; height: 46px"
          >
            —— 预定流程及须知 ——
          </div>
          <img
            src="https://file.sdyxmall.com/h5/v1/public/app/img/group5.b0b24d0.png"
            alt=""
          />
        </div>
        <div class="content mb-45">
          <p class="big-item">一、关于发货</p>
          <p class="small-item">
            个工作日内发出（周末、法定节假日需顺延）个工作日内发出（周末、法定节假日需顺延）；
          </p>
          <p class="big-item">二、关于发货</p>
          <p class="small-item">
            个工作日内发出（周末、法定节假日需顺延）个工作日内发出（周末、法定节假日需顺延）；
          </p>
          <p class="big-item">三、关于发货</p>
          <p class="small-item">
            个工作日内发出（周末、法定节假日需顺延）个工作日内发出（周末、法定节假日需顺延）；
          </p>
          <p class="big-item">四、关于发货</p>
          <p class="small-item">
            个工作日内发出（周末、法定节假日需顺延）个工作日内发出（周末、法定节假日需顺延）；
          </p>
          <p class="big-item">四、关于发货</p>
          <p class="small-item">
            个工作日内发出（周末、法定节假日需顺延）个工作日内发出（周末、法定节假日需顺延）；
          </p>
        </div>
      </div>
    </div>
    <van-goods-action>
      <van-goods-action-icon icon="chat-o" text="客服" />
      <van-goods-action-icon icon="like-o" text="已收藏" />
      <van-goods-action-icon
        icon="cart-o"
        text="购物车"
        to="/cart"
        :badge="cartNum"
      />
      <van-goods-action-button
        type="warning"
        @click="addCart"
        text="加入购物车"
      />
      <van-goods-action-button type="danger" text="立即购买" />
    </van-goods-action>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import $http from "@/utils/http";
export default {
  components: {},
  data() {
    return {
      areaList,
      value: 1,
      current: 0,
      show: false,
      show2: false,
      productId: 0, //商品id
      imgList: [], //轮播图
      detailObj: {}, //商品详情
      descPics: [], //产品图片
      cartNum: 0, //定义购物车数量
    };
  },
  created() {
    this.productId = this.$route.params.productId;
    this.getProductDetail();
  },

  methods: {
    //添加购物车
    addCart() {
      $http
        .post("cart/add", { productId: this.productId })
        .then((res) => {
          //未登录
          if (!res) {
            return false;
          }
          this.cartNum++;
        })
        
    },
    //获取商品详情
    getProductDetail() {
      let productId = this.productId;
      $http
        .get("/product/detail/", {
          params: { productId },
        })
        .then((res) => {
          this.imgList = res.result.banners;
          this.detailObj = res.result;
          this.descPics = res.result.descPics;
          this.cartNum = res.result.cartNum;
        });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onChange(index) {
      this.current = index;
    },
  },
};
</script>
<style lang="less" scoped>
.product_detail {
  .van-nav-bar /deep/ .van-icon,
  .van-nav-bar /deep/.van-nav-bar__text {
    color: #c03131;
  }
  .van-swipe img {
    width: 100%;
    height: 375px;
  }
  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
  }
  .item-info {
    background-color: #fff;
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid #ccc;
  }
  .item-info-container {
    font-size: 15px;
    color: #2c3e50;
  }
  .label {
    height: 15px;
    line-height: 15px;
    display: inline-block;
    text-align: center;
    color: #fff;
    width: 40px;
    margin-right: 5px;
  }
  .item-info-slave {
    font-size: 13px;
    margin-top: 8px;
  }

  .color-gray {
    color: #797d82;
  }
  .price {
    color: #c03131;
  }
  .baged {
    background-color: #fff;
    padding: 10px 13px;
    height: 38px;
  }
  .images img {
    width: 100%;
  }

  .content {
    padding: 15px;
    background-color: #fff;
    .big-item,
    .small-item {
      margin: 12px 0;
      font-size: 12px;
      color: #a6a6a6;
      line-height: 200%;
    }
  }

  /deep/ .van-goods-action-icon__icon {
    font-size: 30px;
  }
  .van-goods-action-button--first {
    width: 150px;
    border-bottom-left-radius: 0;
  }
  .van-goods-action-button--last {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .topic {
    margin: 0 15px;
    margin-top: 15px;
    img {
      width: 110px;
      height: 110px;
    }
  }
  .peisong {
    padding: 10px 15px;
  }
  .count-box {
    padding: 20px;
    .count {
      margin-bottom: 15px;
    }

    /deep/ .van-stepper__input {
      box-sizing: border-box;
      width: 86px;
      height: 30px;
    }
  }
}
</style>
